import back_img from '../public/feather chevron left 1.png';
import { BackText } from '../components/Text';
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
	const navigate = useNavigate();
	return (
		<>
			<div onClick={() => navigate(-1)} 
				style ={{display: 'flex', flexDirection:'row', textDecoration: 'none', marginLeft: '20px', marginTop: '50px'}}
			>
				<img alt = '<' src = {back_img} style = {{width:'25px', height: '25px'}}/>
				<BackText>Back</BackText>
			</div>
			<div style={{margin: '30px 30px 50px 30px', color: 'white'}}>
				<h3>นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)</h3>
				<h4>บทนำ</h4>
				<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					โครงการ 88 SANDBOX แห่งมหาวิทยาลัยธรรมศาสตร์ (ต่อไปนี้เรียกว่า “โครงการฯ”) 
					ให้ความสําคัญกับการคุ้มครองข้อมูลส่วนบุคคลการล่วงละเมิดสิทธิในข้อมูลส่วนบุคคลการควบคุม
					การใช้ข้อมูลและการป้องกันการรั่วไหลของข้อมูลส่วนบุคคลของผู้ใช้บริการ จึงได้จัดทํานโยบาย
					คุ้มครองข้อมูลส่วนบุคคลตามกฎหมาย คุ้มครองข้อมูลส่วนบุคคลขึ้นเพื่อให้เป็นผู้ควบคุมข้อมูลส่วนบุ
					คคลและผู้ประมวลผลตามกฎหมายมีหน้าที่ และความรับผิดชอบตามพระราชบัญญัติคุ้มครองข้อมูล
					ส่วนบุคคล พ.ศ. 2562 ให้การกํากับดูแลการประมวลผลข้อมูลส่วนบุคคลและมาตรการป้องกัน
					คุ้มครองข้อมูลส่วนบุคคลรวมถึงการบริหารความเสี่ยงในการปฏิบัติหรืองดเว้นการปฏิบัติใด ๆ 
					ที่เป็นการฝ่าฝืนกฎหมายเพื่อให้บุคลากรของบริษัทยึดถือเป็นแนวปฏิบัติอย่างเคร่งครัด</p>
				<h4>การเปิดเผยข้อมูลส่วนบุคคล</h4>
				<ul>
					<li>
						โครงการฯ จะไม่ยอมขายหรือเผยแพร่ข้อมูลส่วนตัวของผู้ใช้ให้แก่บุคคลที่สามอื่นหรือองค์กรอื่นใด
					</li>
					<li>
						การเปิดเผยข้อมูลจะเกิดขึ้นก็ต่อเมื่อผู้ใช้เป็นผู้ร้องขอและให้ความยินยอมให้โครงการฯดําเนินการให้ 
						หรือการเปิดเผยข้อมูลนั้นเป็นไปตามที่กฎหมายอนุญาต หรือเป็นไปตามที่กฎหมายกําหนด อาทิ 
						การเปิดเผยข้อมูลตามคําสั่งเจ้าหน้าที่ของรัฐซึ่งกฎหมายได้ให้อํานาจไว้ตามคําสั่งศาลหรือตามหมายศาล
					</li>
					<li>
						เพื่อการพัฒนาและบํารุงรักษาระบบของโครงการฯ ให้มีความปลอดภัยและมีประสิทธิภาพสูงสุด โครงการฯ 
						อาจทําความตกลงไว้กับบุคคลที่สามเพื่อให้ดําเนินงานกับโครงการฯ หรือดําเนินการในนามของโครงการฯ 
						ทั้งนี้บุคคลที่สามนั้นจะต้องยอมรับข้อตกลงคุ้มครองข้อมูลส่วนตัวของผู้ใช้ไว้เป็นความลับเช่นเดียวกัน
					</li>
				</ul>
				<h4>การเก็บรักษาข้อมูล</h4>
				<ul>
					<li>
						โครงการฯ จะไม่ใช้ข้อมูลส่วนตัวของผู้ใช้เพื่อวัตถุประสงค์ใดนอกจากให้บริการหรือเท่าที่กฎหมายกําหนด 
						โครงการฯเคารพและคํานึงถึงความเป็นส่วนตัวของผู้ให้ข้อมูลของโครงการอย่างสูงสุด โครงการต้องการ
						ให้ผู้ใช้สบายใจและมั่นใจในการเป็นส่วนหนึ่งของโครงการและเชื่อถือความปลอดภัยของข้อมูลส่วนตัว
					</li>
					<li>
						ผู้ใช้มีหน้าที่ปกป้องข้อมูลบนอุปกรณ์ของคุณเช่นการใช้โปรแกรมป้องกันไวรัส, การอัพเดตซอฟท์แวร์, 
						เปลี่ยนรหัส ผ่าน, และไม่ให้บุคคลที่สามเข้าถึงอุปกรณ์ของคุณได้ไม่ว่าทางใด
					</li>
				</ul>
				<h4>ข้อมูลส่วนบุคคลที่ถูกเก็บรวบรวม และวัตถุประสงค์</h4>
				<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					ทางโครงการฯ จะมีการเก็บข้อมูลส่วนบุคคลทั่วไป เช่น ชื่อ-นามสกุล, เบอร์โทรศัพท์, อีเมล์ 
					ด้วยวัตถุประสงค์เพื่อการดำเนินการที่จำเป็นของโครงการฯ เช่น การประชาสัมพันธ์กิจกรรมของโครงการฯ 
					การติดต่อเข้ารับการอบรมจากโครงการฯ เป็นต้น และเพื่อให้ผู้ใช้ได้รับประโยชน์จากการเป็นส่วนหนึ่งของโครงการฯ 
					เช่น เพื่อใช้ในการเข้าร่วมงานกิจกรรมของโครงการฯ
				</p>
				<h4>ระยะเวลาในการจัดเก็บข้อมูล</h4>
				<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					โครงการฯ จะเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้ไว้ตามระยะเวลาที่จำเป็นในระหว่างที่ผู้ใช้เป็นสมาชิกหรือมีความ
					สัมพันธ์อยู่ในโครงการฯ หรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้ 
					ในกรณีทั่วไประยะเวลาการเก็บข้อมูลสูงสุดจะเท่ากับ 10 ปี เว้นแต่จะมีกฎหมายกำหนดให้เก็บรักษาข้อมูลไว้เป็น
					ระยะเวลานานกว่าที่กำหนดไว้ดังกล่าง
				</p>
				<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					ทั้งนี้ บริษัทจะลบหรือทำลายข้อมูลส่วนบุคคล หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุถึงตัวตนของ
					ผู้ใช้ได้เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว
				</p>
				<h4>การติดต่อบริษัทและเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h4>
				<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					หากผู้ใช้มีข้อเสนอแนะ หรือต้องการสอบถามข้อมูลเกี่ยวกับรายละเอียดการเก็บรวบรวม ใช้ และ/หรือ
					เปิดเผยข้อมูลส่วนบุคคลของผู้ใช้ รวมถึงการขอใช้สิทธิตามนโยบายฉบับนี้ ผู้ใช้สามารถติดต่อโครงการฯ 
					และ/หรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล ผ่านช่องทาง 88sandbox@gmail.com
				</p>
			</div>
		</>
  	)
}
