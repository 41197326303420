import Center from '../components/Center';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import { RegisButton } from '../components/Button';
import TransBox from '../components/TransBox';
import { CreateCard, TitleRegis, BackText, CreateAs } from '../components/Text';
import { MyLink } from '../components/MyLink';
import { UserAsResponse, USERTYPE_TO_INT } from '../controller/UsersAPI';
import back_img from '../public/feather chevron left 1.png';
function Register (props: {user: UserAsResponse, setUser: (user: UserAsResponse) => void}){
    const {user, setUser} = props;
    return ( 
        <>
            <Link to='/' style ={{position: 'absolute', display: 'flex', flexDirection:'row', left: '20px', textDecoration: 'none'}}>
                <img alt = '<' src = {back_img} style = {{width:'25px', height: '25px'}}/>
                <BackText>Home</BackText>
            </Link>
            <Center>
                <div style = {{ margin:'20px' }}>
                    <CreateAs>You are at</CreateAs>
                    <CreateCard>Create My Card</CreateCard>
                </div>
                <TransBox>
                    <TitleRegis>
                        Who are you?
                    </TitleRegis>   
                    <MyLink to = '/regis-form' onClick={(e) => {
                        let tmpUser = user;
                        tmpUser.user_type = USERTYPE_TO_INT["StartupTeam"];
                        setUser(tmpUser);
                    }}>
                        <RegisButton customColor = '#8896FF'>
                            Startup Team
                        </RegisButton>
                    </MyLink>
                    <MyLink to = '/regis-form' onClick={(e) => {
                        let tmpUser = user;
                        tmpUser.user_type = USERTYPE_TO_INT["Mentor"];
                        setUser(tmpUser);
                    }}>
                        <RegisButton customColor = '#EE93DA'>
                            Mentor
                        </RegisButton>
                    </MyLink>
                    <MyLink to = '/regis-form' onClick={(e) => {
                        let tmpUser = user;
                        tmpUser.user_type = USERTYPE_TO_INT["VC"];
                        setUser(tmpUser);
                    }}>
                        <RegisButton customColor = '#EC7478'>
                            Venture Capital
                        </RegisButton>
                    </MyLink>
                    <MyLink to = '/regis-form' onClick={(e) => {
                        let tmpUser = user;
                        tmpUser.user_type = USERTYPE_TO_INT["TUStudent"];
                        setUser(tmpUser);
                    }}>
                        <RegisButton customColor = '#F6853F'>
                            TU Student
                        </RegisButton>
                    </MyLink>
                    <MyLink to = '/regis-form' onClick={(e) => {
                        let tmpUser = user;
                        tmpUser.user_type = USERTYPE_TO_INT["Visitor"];
                        setUser(tmpUser);
                    }}>
                        <RegisButton customColor = '#FFC437'>
                            Visitor
                        </RegisButton>
                    </MyLink>
                    <MyLink to = '/regis-form' onClick={(e) => {
                        let tmpUser = user;
                        tmpUser.user_type = USERTYPE_TO_INT["RoadShow"];
                        setUser(tmpUser);
                    }}>
                        <RegisButton customColor = '#C1E86A'>
                            Roadshow
                        </RegisButton>
                    </MyLink>
                </TransBox>
            </Center>
        </>
    ); 
} 
export default Register;