import React from 'react'
import { ErrorBorder } from '../components/Border'
import Center from '../components/Center'
import { ErrorHeader, ErrorDesc } from '../components/Text'
import sad from '../public/sad.png'

export default function Error404() {
  return (
    <Center>
        <div style = {{ margin: '200px 20px 300px 20px' }}>
            <img src = {sad} style = {{ height: '150px'}}/>
            <ErrorHeader>
                404 Error
            </ErrorHeader>
            <Center style = {{ margin: '0' }}>
                <ErrorBorder/>
            </Center>
            <ErrorDesc>
                คอร์นไม่คุ้นกับความผิดพลาดในครั้งนี้เลยแฮะ... <br/> ลองปิดเว็บไซต์นี้ แล้วกดเข้ามาดูใหม่งับ<br/>คอร์นรออยู่น้าา
            </ErrorDesc>
        </div>    
    </Center>
  )
}
