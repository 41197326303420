
import { GeneralResponse } from "./Common";

const SHEETAPI_URL = `${process.env.REACT_APP_API_URL}/api/sheet`;

interface TeamNameResponse extends GeneralResponse {
    team_names: string[],
    updated_at: Date
}

export async function getTeamNames(){
    return fetch(`${SHEETAPI_URL}/team_names`).then(res => res.json() as Promise<TeamNameResponse>);
}