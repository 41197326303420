
import { GeneralResponse } from "./Common";

const FACULTYAPI_ROUTE = `${process.env.REACT_APP_API_URL}/api/db/faculty`;

export interface Faculty {
    faculty_id: number,
    name: string
}

export interface GetFacultyResponse extends GeneralResponse {
    faculties: Faculty[]
}

export async function getFaculty(){ // should be cached
    return fetch(`${FACULTYAPI_ROUTE}/`).then(res => res.json() as Promise<GetFacultyResponse>);
}
