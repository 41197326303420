import { Link } from "react-router-dom";
import styled from "styled-components";

const MyLink = styled(Link)`
    text-decoration: none;
`;

const MyLink2 = styled(MyLink)`
    color: #CDD3FF;
`;

const EditLink = styled(Link)`
    font-size: 1rem;
    color: #6072F3;
    padding-left: 170px;
`;

export { MyLink, MyLink2, EditLink};