import styled from "styled-components";

export const FacultySelect = styled.select`
    display: inline;
    margin-bottom: 2px;
    width: 80vw;
    max-width: 560px;
    height: 54px;
    background: transparent;
    border: 1px solid #EFF1FF;
    font-family: 'Kanit', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    padding:  0 1rem 0 1rem;
    color: #888888;
    :focus {
        border-radius: 0;
    }
`;

export const FacultyOption = styled.option`
    color: #000000;
`;