import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './view/Home';
import RegisMenu from './view/RegisMenu';
import Card from './view/Card';
import RegisForm from './view/RegisForm';
import Error404 from './view/Error404';
import RegisTop from './view/RegisTop';
import TransBox from './components/TransBox';
import liff from '@line/liff';
import { Component, ReactNode } from 'react';
import { searchUserByUserID, UserAsResponse } from './controller/UsersAPI';
import { Faculty, getFaculty } from './controller/FacultyAPI';
import PrivacyPolicy from './view/PrivacyPolicy';

const defaultProfilePic = "/logo192.png";
class App extends Component<{}, {profilePic: string, isRegis: boolean, condition: boolean, user: UserAsResponse, faculties: Faculty[]}> {
    setUser = (user: UserAsResponse) => this.setState({user: user});
    setProfilePic = (profilePic: string) => this.setState({profilePic: profilePic});
    setIsRegis = (isRegis: boolean) => this.setState({isRegis: isRegis});
    setCondition = (condition: boolean) => this.setState({condition: condition});
    constructor(props: any){
        super(props);
        console.log("api route: ", process.env.REACT_APP_API_URL);
        this.state = {
            profilePic: defaultProfilePic,
            isRegis: false,
            condition: false,
            user: {
                user_id: "",
                firstname: "",
                lastname: "",
                birthday: new Date(),
                email: "",
                nickname: "",
                phone_number: "",
                team_name: "",
                created_at: new Date(),
                running_number: -1,
                faculty_id: -1,
                user_type: -1,
                student_id: "",    
            },
            faculties: [],
        };

    }
    
    componentDidMount(){
        liff.init({liffId: process.env.REACT_APP_LIFF_ID})
            .then(() => {
                if(!liff.isLoggedIn()){
                    liff.login();
                }
                liff.getProfile().then((profile) => {
                    let tmpUser = this.state.user;
                    tmpUser.user_id = profile.userId;
                    this.setUser(tmpUser);
                    
                    this.setProfilePic(profile.pictureUrl ? profile.pictureUrl : defaultProfilePic);
                    searchUserByUserID(profile.userId).then(({error, message, user}) => { // check if already registered
                        if(error) alert("internal error: " + message);
                        else if(!error && user !== null) {
                            this.setIsRegis(true);
                            this.setUser(user);
                        };
                    });
                });
            })
            .catch((e) => {
                console.error("init error", e);
            });
            getFaculty()
                .then((v) => {
                    if(!v.error){
                        this.setState({
                            faculties: v.faculties
                        })
                    }
            })
    }

    render(): ReactNode {
        return (
            <BrowserRouter> 
                <Routes>
                    <Route path ="/" element = {<Home isRegis={this.state.isRegis}/>} />
                    <Route path ="/register" element = {<RegisMenu user={this.state.user} setUser={this.setUser} />} />
                    <Route path ="/card" element = {
                        <>
                            <RegisTop user_type={this.state.user.user_type}/>
                            <TransBox>
                                <Card QRcontent={String(this.state.user.running_number)} 
                                    profilePic={this.state.profilePic} 
                                    user={this.state.user} 
                                    setIsRegis={this.setIsRegis} 
                                    setCondition={this.setCondition}
                                    faculties={this.state.faculties}
                                />
                            </TransBox>
                        </>
                    } />
                    <Route path ="/regis-form" element = {
                        <RegisForm 
                            profilePic={this.state.profilePic} 
                            user={this.state.user} 
                            setUser={this.setUser} 
                            isRegis={this.state.isRegis} 
                            setIsRegis={this.setIsRegis} 
                            condition={this.state.condition} 
                            setCondition={this.setCondition}
                            faculties={this.state.faculties}
                        />
                    } />
                    <Route path ="/privacy-policy" element = {<PrivacyPolicy />} />
                    <Route path ="*" element = {<Error404/>} />
                </Routes>
            </BrowserRouter>
        );        
    }
}
export default App;
