import styled from "styled-components";
export const Border = styled.div`
    border-top: 1px solid rgb(100, 100, 100, 0.3); 
    width: 150px;
    margin: 5px 0 5px 0;
`;

export const ErrorBorder = styled(Border)`
    border-top: 2px solid rgba(255, 238, 237, 0.1);
    width: 250px;
    margin: 5px 0 5px 0;
`;