import Center from '../components/Center';
import { PrimaryButton } from '../components/Button';
import TransBox from '../components/TransBox';
import { TextBox, CheckBox} from '../components/InputBox';
import { ConditionLabel, LabelInputText,BackText, TitleRegis, RequirementText } from '../components/Text';
import { MyLink2 } from '../components/MyLink';
import back_img from '../public/feather chevron left 1.png';
import RegisTop from './RegisTop';
import { getTeamNames } from '../controller/SheetAPI';
import { FacultySelect, FacultyOption,} from '../components/SelectBox';
import { useState, useEffect } from 'react';
import { Faculty } from '../controller/FacultyAPI';
import { useNavigate} from "react-router-dom";
import { startupTeamRegister, 
    mentorRegister, 
    tuStudentRegister, 
    visitorRegister, 
    roadShowRegister, 
    vcRegister, 
    UserAsResponse, 
    USERTYPE_TO_INT, 
    InsertUserResponse, 
    editUser } from '../controller/UsersAPI';

interface RegisFormProps {
    profilePic: string, 
    user: UserAsResponse, 
    setUser: (user: UserAsResponse) => void, 
    condition: boolean,
    setCondition: (v:boolean) => void,
    isRegis: boolean,
    setIsRegis: (v:boolean) => void
    faculties: Faculty[],
}

function RegisForm(props: RegisFormProps) { 
    const {
        firstname, 
        lastname, 
        birthday, 
        email, 
        nickname, 
        phone_number, 
        user_id, 
        faculty_id, 
        organization, 
        position, 
        student_id, 
        team_name,
        user_type
    } = props.user;
    const {user, setUser, condition, setCondition, isRegis, setIsRegis, faculties} = props;
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [nicknameErr, setNicknameErr] = useState(false);
    const [birthdayErr, setBirthdayErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [phoneErr, setPhoneErr] = useState(false);
    const [facErr, setFacErr] = useState(false);
    const [OrgErr, setOrgErr] = useState(false);
    const [positionErr, setPositionErr] = useState(false);
    const [studentIDErr, setStudentIDErr] = useState(false);
    const [teamNameErr, setTeamNameErr] = useState(false);
    const navigate = useNavigate();
    const validTUID = new RegExp('^[0-9]{10}$');
    const validPhoneNum = new RegExp('^[0-9]{10}$');
    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
    // const [facultyOption, setFacultyOption] = useState<JSX.Element[]>([]);
    const [TeamNamesOption, setTeamNamesOption] = useState<JSX.Element[]>([]);
    
    useEffect(() => {
        if(user_type === USERTYPE_TO_INT["StartupTeam"]){
            getTeamNames().then((r) => {
                if(!r.error){
                    setTeamNamesOption(r.team_names.map((x,index) => <FacultyOption key={index} value={x}>{x}</FacultyOption>));
                }
                else{
                    console.log(r.message);
                }
            })
        }
    }, [user_type])

    const postData = async () => {        
        let regisPromise:Promise<InsertUserResponse> | null = null;
        if(user_type === USERTYPE_TO_INT["StartupTeam"]){
            regisPromise = startupTeamRegister(user_id, firstname, lastname, nickname, email, phone_number, birthday, team_name as string);
        }
        else if(user_type === USERTYPE_TO_INT["Mentor"]){
            regisPromise = mentorRegister(user_id, firstname, lastname, nickname, email, phone_number, birthday, organization as string, position as string);
        }
        else if(user_type === USERTYPE_TO_INT["TUStudent"]){
            regisPromise = tuStudentRegister(user_id, firstname, lastname, nickname, email, phone_number, birthday, Number(faculty_id), student_id as string); 
        }
        else if(user_type === USERTYPE_TO_INT["Visitor"]){
            regisPromise = visitorRegister(user_id, firstname, lastname, nickname, email, phone_number, birthday);
        }
        else if(user_type === USERTYPE_TO_INT["RoadShow"]){
            regisPromise = roadShowRegister(user_id, firstname, lastname, nickname, email, phone_number, birthday);
        }
        else if(user_type === USERTYPE_TO_INT["VC"]){
            regisPromise = vcRegister(user_id, firstname, lastname, nickname, email, phone_number, birthday, organization as string, position as string);
        }
        
        if(regisPromise){
            regisPromise
                .then((v) => {
                    if(!v.error){ // create success goes here
                        console.log('submission success');
                        setUser(v.user);
                        setIsRegis(true);
                        navigate("/card");
                    }
                    else{ // create fail goes here
                        // alert("probably already register: " + v.message);
                        console.error(v.message);
                    }
                })
                .catch((e) => { // promise problem goes here
                    console.error("internal problem?", e);
                    alert("internal problem?" + String(e));
                });
        }
        else{
            alert("wrong role?")
        }
    }

    const editData = async () => {
        const editRes = editUser(user_id, firstname, lastname, nickname, email, phone_number, birthday, team_name, organization, position, faculty_id, student_id);
        editRes.then(v => {
            if(!v.error){
                console.log('submission success');
                navigate("/card");
            }
            else{
                // alert("probably already register: " + v.message);
                console.error(v.message);
            }
        })
        .catch(e => {
            console.error("internal problem?", e);
            alert("internal problem?" + String(e));
        })
    }

    const formValidate = () => {
        if(!user.firstname) {
            setFirstNameErr(true); 
            return false;
        }else setFirstNameErr(false)
        if(!user.lastname) {
            setLastNameErr(true); 
            return false;
        }else setLastNameErr(false)
        if(!user.nickname) {
            setNicknameErr(true); 
            return false;
        }else setNicknameErr(false)
        if(!user.birthday) {
            setBirthdayErr(true); 
            return false;
        }else setBirthdayErr(false)
        if (user_type === USERTYPE_TO_INT["StartupTeam"]){
            if(!user.team_name){
                setTeamNameErr(true);
                return false;
            }else setTeamNameErr(false)
        }
        if (user_type === USERTYPE_TO_INT["Mentor"] || user_type === USERTYPE_TO_INT["VC"]){
            if(!user.organization){
                setOrgErr(true);
                return false;
            }else setOrgErr(false)
            if(!user.position){
                setPositionErr(true);
                return false;
            }else setPositionErr(false)
        }
        if (user_type === USERTYPE_TO_INT["TUStudent"]){
            if(!user.student_id || validTUID.test(user.student_id)===false){
                setStudentIDErr(true);
                return false;
            }else setStudentIDErr(false)
            if(user.faculty_id===-1){
                setFacErr(true);
                return false;
            }else setFacErr(false)
        }
        if(!user.email || validEmail.test(user.email)===false) {
            setEmailErr(true); 
            return false;
        }else setEmailErr(false)
        if(!user.phone_number || validPhoneNum.test(user.phone_number)===false) {
            setPhoneErr(true); 
            return false;
        }else setPhoneErr(false)
        return true;
    }
    const onSubmit = (e: any) => {
        let formvalid = formValidate();
        e.preventDefault(); //refresh prevented
        
        if(formvalid){
            if(isRegis){
                editData();
            }
            else {
                postData();
            }
        }
        
    }

    return ( 
        <>
            <div onClick={() => navigate(-1)} 
                style ={{position: 'absolute', display: 'flex', flexDirection:'row', left: '20px', textDecoration: 'none'}}
            >
                <img alt = '<' src = {back_img} style = {{width:'25px', height: '25px'}}/>
                <BackText>Back</BackText>
            </div>
            <Center>
                <RegisTop user_type={user.user_type} />
                <TransBox>
                    <TitleRegis>Personal Information</TitleRegis>
                    <form onSubmit = { onSubmit } style={{paddingLeft: '30px', paddingRight: '30px'}}>
                        <LabelInputText>First name</LabelInputText>
                        <TextBox type = 'text' placeholder = 'กรุณาระบุชื่อจริงเป็นภาษาอังกฤษ'
                            value = { firstname }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.firstname = e.target.value;
                                    setUser(tmpUser);
                                }
                            }
                        />
                        {firstNameErr && <RequirementText>First name is required.</RequirementText>}
                        <LabelInputText>Last name</LabelInputText>
                        <TextBox type = 'text' placeholder = 'กรุณาระบุนามสกุลเป็นภาษาอังกฤษ'
                            value = { lastname }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.lastname = e.target.value;
                                    setUser(tmpUser);
                                }
                            }
                        />
                        {lastNameErr && <RequirementText>Last name is required.</RequirementText>}
                        <LabelInputText>Nickname</LabelInputText>
                        <TextBox type = 'text' placeholder = 'กรุณาระบุชื่อเล่นเป็นภาษาอังกฤษ'
                            value = { nickname }
                            onChange = { (e) => {
                                let tmpUser = user;
                                tmpUser.nickname = e.target.value;
                                setUser(tmpUser);
                            }
                        }
                        />
                        {nicknameErr && <RequirementText>Nickname is required.</RequirementText>}
                        <LabelInputText>Birthdate</LabelInputText>
                        <TextBox type = 'date' placeholder = 'กรุณาระบุวันเกิดของคุณ'
                            value = { birthday.toISOString().split("T")[0] }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.birthday = new Date(e.target.value);
                                    setUser(tmpUser);
                                }
                            } 
                        />
                        {birthdayErr && <RequirementText>Birthdate is required.</RequirementText>}
                        { user_type === USERTYPE_TO_INT["StartupTeam"]? 
                            <>
                                <LabelInputText>Team</LabelInputText>
                                <TextBox type = 'text' placeholder = 'กรุณาระบุชื่อทีมของคุณ' list = 'team_names'
                                    value = { team_name }
                                    onChange = { (e) => {
                                            let tmpUser = user;
                                            tmpUser.team_name = e.target.value;
                                            setUser(tmpUser);
                                        }
                                    }
                                />
                                <datalist id = 'team_names'>
                                    {TeamNamesOption}
                                </datalist>
                                {teamNameErr && <RequirementText>Team is required.</RequirementText>}
                            </>: null 

                        }                      
                        { user_type === USERTYPE_TO_INT["Mentor"] || user_type === USERTYPE_TO_INT["VC"]? 
                            <>
                                <LabelInputText>Organization</LabelInputText>
                                <TextBox type = 'text' placeholder = 'กรุณาระบุชื่อบริษัทที่คุณกำลังทำงาน'
                                    value = { organization }
                                    onChange = { (e) => {
                                            let tmpUser = user;
                                            tmpUser.organization = e.target.value;
                                            setUser(tmpUser);
                                        }
                                    }
                                />
                                {OrgErr && <RequirementText>Organization is required.</RequirementText>}
                                <LabelInputText>Position</LabelInputText>
                                <TextBox type = 'text' placeholder = 'กรุณาระบุตำแหน่งในที่ทำงานของคุณ'
                                    value = { position }
                                    onChange = { (e) => {
                                            let tmpUser = user;
                                            tmpUser.position = e.target.value;
                                            setUser(tmpUser);
                                        }
                                    }
                                />
                                {positionErr && <RequirementText>Position is required.</RequirementText>}
                            </>: null 
                        }
                        { user_type === USERTYPE_TO_INT["TUStudent"]? 
                            <>
                                <LabelInputText>Student ID</LabelInputText>
                                <TextBox type = 'number' placeholder = 'กรุณาระบุเลขประจำตัวนักศึกษา 10 หลัก' 
                                    value = { student_id }
                                    onChange = { (e) => {
                                        let tmpUser = user;
                                        tmpUser.student_id = e.target.value;
                                        setUser(tmpUser);
                                    }}
                                />
                                {studentIDErr && <RequirementText>10-digit student ID is required.</RequirementText>}
                                <LabelInputText>Faculty</LabelInputText>
                                {!isRegis?
                                    <>
                                        <FacultySelect id = 'faculty_list'
                                            value = { Number(faculty_id) }
                                            onChange = { (e) => {
                                                if(Number(e.target.value)!==-1) e.target.style.color='#CDD3FF';
                                                    let tmpUser = user;
                                                    tmpUser.faculty_id = Number(e.target.value);
                                                    setUser(tmpUser);
                                                }
                                            }
                                        >
                                            <FacultyOption value={Number(-1)} style={{color:'#888888'}} disabled>
                                                กรุณาเลือกคณะของคุณ
                                            </FacultyOption>
                                            {faculties && faculties.map<JSX.Element>((x) => {return(<FacultyOption key={x.faculty_id} value={x.faculty_id} >{x.name}</FacultyOption>);})}
                                        </FacultySelect> 
                                        {facErr && <RequirementText>Faculty is required.</RequirementText>}
                                    </>   
                                        :
                                    <> 
                                        <FacultySelect id = 'faculty_list' style = {{color: '#CDD3FF'}}
                                            value = { Number(faculty_id) }
                                            onChange = { (e) => {
                                                if(Number(e.target.value)!==-1) e.target.style.color='#CDD3FF';
                                                    let tmpUser = user;
                                                    tmpUser.faculty_id = Number(e.target.value);
                                                    setUser(tmpUser);
                                                }
                                            }
                                        >
                                            <FacultyOption value={Number(-1)} style={{color:'#888888'}} disabled>
                                                กรุณาเลือกคณะของคุณ
                                            </FacultyOption>
                                            {faculties && faculties.map<JSX.Element>((x) => {return(<FacultyOption key={x.faculty_id} value={x.faculty_id} >{x.name}</FacultyOption>);})}
                                        </FacultySelect>
                                        {facErr && <RequirementText>Faculty is required.</RequirementText>}
                                    </>
                                }
                            </>  : null 
                        }   
                        <LabelInputText>Email</LabelInputText>
                        <TextBox type = 'text' placeholder = 'กรุณาระบุอีเมลของคุณ'
                            value = { email }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.email = e.target.value;
                                    setUser(tmpUser);
                                }
                            }
                        />
                        {emailErr && <RequirementText>Email is required.</RequirementText>}
                        <LabelInputText>Phone No.</LabelInputText>
                        <TextBox type = 'number' placeholder = '0882345678'
                            value = { phone_number }
                            onChange = { (e) => {
                                    let tmpUser = user;
                                    tmpUser.phone_number = e.target.value;
                                    setUser(tmpUser);
                                }
                            }
                        />
                        {phoneErr && <RequirementText>Phone No. is required.</RequirementText>}
                        <ConditionLabel style={{marginTop: '20px'}}>
                            <CheckBox type = 'checkbox' name = 'conditions' onChange = { (e) => setCondition(!condition) } required/>
                            I agree to the&nbsp; 
                            <MyLink2 to = '/privacy-policy'>Privacy Policy.</MyLink2>
                        </ConditionLabel>
                        <PrimaryButton type = 'submit'>
                            {isRegis?
                                <>Edit my card</>
                                :<>Create my card</>
                            }
                            
                        </PrimaryButton>
                    </form>
                </TransBox>
            </Center>
        </>
    )                      
}
export default RegisForm;