
import { GeneralResponse, JSON_HEADER } from "./Common";

const USERAPI_ROUTE = `${process.env.REACT_APP_API_URL}/api/db/user`;
export interface UserBeforeRegister { // all of info we gather from the form and line id
    user_id:         string, 
    firstname:       string, 
    lastname:        string, 
    nickname:        string,
    email:           string,
    phone_number:    string,
    birthday:        Date,    // never forget to convert type in the response

    team_name?:      string, 
    organization?:   string,
    position?:       string,
    faculty_id?:     number,
    student_id?:     string,
}

interface User extends UserBeforeRegister { 
    created_at:      Date,    // bc JSON doesn't know how to differnciate btw string and Date
    user_type:       number,
}
export interface UserAsResponse extends User { // if necessary add export keyword by yourself
    running_number: number,
}

export interface InsertUserResponse extends GeneralResponse {
    user: UserAsResponse
}

export const USERTYPE_TO_INT = Object.freeze({
    "StartupTeam": 0,
    "Mentor": 1,
    "VC": 2,
    "Visitor": 3,
    "TUStudent": 4,
    "RoadShow": 5
});

export interface QueryOneUserResponse extends GeneralResponse {
    user: UserAsResponse
}

export interface DeleteUserResponse extends GeneralResponse {
    deleted : boolean
}

async function userRegister(user: User){
    return fetch(`${USERAPI_ROUTE}/register`, {
        method: "post",
        body: JSON.stringify({user: user}),
        headers: JSON_HEADER
    }).then(res => res.json() as Promise<InsertUserResponse>)
    .then(res => {
        if(res.user){
            res.user.created_at = new Date(res.user.created_at);
            res.user.birthday = new Date(res.user.birthday);
        }
        return res;
    });
}

export async function searchUserByUserID(userID:string){
    return fetch(`${USERAPI_ROUTE}/user_by_user_id/${userID}`)
        .then(res => res.json() as Promise<QueryOneUserResponse>)
        .then(res => { // this is necessary for the one that is datetime
            if(res.user){
                res.user.created_at = new Date(res.user.created_at);
                res.user.birthday = new Date(res.user.birthday);
            }
            return res;
        });
}

export async function searchUserByRunningNumber(runningNumber:number){
    return fetch(`${USERAPI_ROUTE}/user_by_running_number/${runningNumber}`)
        .then(res => res.json() as Promise<QueryOneUserResponse>)
        .then(res => { // this is necessary for the one that is datetime
            if(res.user){
                res.user.created_at = new Date(res.user.created_at);
                res.user.birthday = new Date(res.user.birthday);
            }
            return res;
        });
}

export async function deleteUserByUserID(userID:string){ // currently use for unit testing only
    return fetch(`${USERAPI_ROUTE}/delete_by_user_id`,{
        method: "delete",
        body: JSON.stringify({user_id: userID}),
        headers: JSON_HEADER
    }).then(res => res.json() as Promise<DeleteUserResponse>);
}

export async function tuStudentRegister(
    user_id:         string, 
    firstname:       string, 
    lastname:        string, 
    nickname:        string,
    email:           string,
    phone_number:    string,
    birthday:        Date,
    faculty_id:     number,
    student_id:     string,
){
    const user: User = {
        user_id: user_id,
        firstname: firstname,
        lastname: lastname,
        nickname: nickname,
        email: email,
        phone_number: phone_number,
        birthday: birthday,
        created_at: new Date(),
        user_type: USERTYPE_TO_INT["TUStudent"],
        faculty_id: faculty_id,
        student_id: student_id
    };
    return userRegister(user);
}

export async function startupTeamRegister(
    user_id:         string, 
    firstname:       string, 
    lastname:        string, 
    nickname:        string,
    email:           string,
    phone_number:    string,
    birthday:        Date,
    team_name:       string
){
    const user: User = {
        user_id: user_id,
        firstname: firstname,
        lastname: lastname,
        nickname: nickname,
        email: email,
        phone_number: phone_number,
        birthday: birthday,
        created_at: new Date(),
        user_type: USERTYPE_TO_INT["StartupTeam"],
        team_name: team_name
    };
    return userRegister(user);
}

export async function mentorRegister(
    user_id:         string, 
    firstname:       string, 
    lastname:        string, 
    nickname:        string,
    email:           string,
    phone_number:    string,
    birthday:        Date,
    organization:    string,
    position:        string
){
    const user: User = {
        user_id: user_id,
        firstname: firstname,
        lastname: lastname,
        nickname: nickname,
        email: email,
        phone_number: phone_number,
        birthday: birthday,
        created_at: new Date(),
        user_type: USERTYPE_TO_INT["Mentor"],
        organization: organization,
        position: position
    };
    return userRegister(user);
}

export async function vcRegister(
    user_id:         string, 
    firstname:       string, 
    lastname:        string, 
    nickname:        string,
    email:           string,
    phone_number:    string,
    birthday:        Date,
    organization:    string,
    position:        string
){
    const user: User = {
        user_id: user_id,
        firstname: firstname,
        lastname: lastname,
        nickname: nickname,
        email: email,
        phone_number: phone_number,
        birthday: birthday,
        created_at: new Date(),
        user_type: USERTYPE_TO_INT["VC"],
        organization: organization,
        position: position
    };
    return userRegister(user);
}

export async function visitorRegister(
    user_id:         string, 
    firstname:       string, 
    lastname:        string, 
    nickname:        string,
    email:           string,
    phone_number:    string,
    birthday:        Date,
){
    const user: User = {
        user_id: user_id,
        firstname: firstname,
        lastname: lastname,
        nickname: nickname,
        email: email,
        phone_number: phone_number,
        birthday: birthday,
        created_at: new Date(),
        user_type: USERTYPE_TO_INT["Visitor"]
    };
    return userRegister(user);
}

export async function roadShowRegister(
    user_id:         string, 
    firstname:       string, 
    lastname:        string, 
    nickname:        string,
    email:           string,
    phone_number:    string,
    birthday:        Date,
){
    const user: User = {
        user_id: user_id,
        firstname: firstname,
        lastname: lastname,
        nickname: nickname,
        email: email,
        phone_number: phone_number,
        birthday: birthday,
        created_at: new Date(),
        user_type: USERTYPE_TO_INT["RoadShow"]
    };
    return userRegister(user);
}

export async function editUser(
    user_id:        string,
    firstname:      string | null = null,
    lastname:       string | null = null,
    nickname:       string | null = null,
    email:          string | null = null,
    phone_number:   string | null = null,
    birthday:       Date   | null = null,

    team_name:      string | null = null,
    organization:   string | null = null,
    position:       string | null = null,
    faculty_id:     number | null = null,
    student_id:     string | null = null
){
    return fetch(`${USERAPI_ROUTE}/edit`, {
        method: "PUT",
        body: JSON.stringify({
            user_id: user_id,
            user: {
                firstname: firstname,
                lastname: lastname,
                nickname: nickname,
                email: email,
                phone_number: phone_number,
                birthday: birthday,
                team_name: team_name,
                organization: organization,
                position: position,
                faculty_id: faculty_id,
                student_id: student_id
            }
        }),
        headers: JSON_HEADER
    }).then(res => res.json() as Promise<GeneralResponse>);
}

