import styled from "styled-components";
import startup_card from "../public/Card_BG_StartupTeam.svg"
import mentor_card from "../public/Card_BG_Mentor.svg"
import vc_card from "../public/Card_BG_VC.svg"
import tu_student_card from "../public/Card_BG_TUStudent.svg"
import visitor_card from "../public/Card_BG_Visitor.svg"
import roadshow_card from "../public/Card_BG_Roadshow.svg"
import { USERTYPE_TO_INT } from "../controller/UsersAPI";

function Background(props: {user_type:number}){
    const user_type = props.user_type;
    switch(user_type){
        case USERTYPE_TO_INT["StartupTeam"]: return startup_card;
        case USERTYPE_TO_INT["Mentor"]: return mentor_card
        case USERTYPE_TO_INT["VC"]: return vc_card;
        case USERTYPE_TO_INT["TUStudent"]: return tu_student_card;
        case USERTYPE_TO_INT["Visitor"]: return visitor_card;
        case USERTYPE_TO_INT["RoadShow"]: return roadshow_card;
        default: return visitor_card;
    }
}

const CardBG = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    padding: 0 20px 25px 20px;
    margin-bottom: 40px;
`;

export default CardBG;