import Center from '../components/Center';
import { CreateAs, MemType } from '../components/Text';
import { USERTYPE_TO_INT } from '../controller/UsersAPI';

function RegisTop(props: {user_type: number}){
    let customColor = "";
    let role = "";
    switch(props.user_type){
        case USERTYPE_TO_INT.StartupTeam:
            customColor = '#8896FF';
            role = "Startup Team";
            break;
        case USERTYPE_TO_INT.Mentor:
            customColor = '#EE93DA';
            role = "Mentor";
            break;
        case USERTYPE_TO_INT.VC:
            customColor = '#EC7478';
            role = "Venture Capital";
            break;
        case USERTYPE_TO_INT.TUStudent:
            customColor = '#F6853F';
            role = "TU Student";
            break;
        case USERTYPE_TO_INT.Visitor:
            customColor = '#FFC437';
            role = "Visitor";
            break;
        case USERTYPE_TO_INT.RoadShow:
            customColor = '#C1E86A';
            role = "Roadshow Participant";
            break;
    } 
    return ( 
        <Center>
            <div style = {{ margin:'20px' }}>
                <CreateAs>Create card as</CreateAs>
                <MemType color = { customColor }>{ role }</MemType>
            </div>
        </Center>
    ); 
} 
export default RegisTop;