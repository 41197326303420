import React from 'react' 
import Center from '../components/Center';
import { BlackButton, PrimaryButton, DisableButton } from '../components/Button';
import Logo from './Logo';
import { MyLink } from '../components/MyLink';
interface HomeProps {
    isRegis: boolean,
}
function Home (props: HomeProps){
    const { isRegis } = props;
        return ( 
            <Center>
                <Logo />
                <div style={{ marginTop: '70px', height: '68vh' }}>

                    { isRegis?
                        <>
                            <DisableButton>Create my card</DisableButton><br/>
                            <MyLink to="/card">
                                <BlackButton>My card</BlackButton>
                            </MyLink>
                        </>
                    :null }
                    { !isRegis?
                        <>
                            <MyLink to="/register">
                                <PrimaryButton>Create my card</PrimaryButton>
                            </MyLink><br/>
                            <DisableButton>My card</DisableButton>
                        </>
                    :null }
                </div>    
            </Center>
        );
} 
export default Home;