import styled from "styled-components";

const BlackButton = styled.button`
    display: inline;
    text-align: center;
    color: #EFF1FF;
    width: 224px;
    height: 56px;
    font-size: 1.5rem;
    font-family: 'Kanit', sans-serif;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    background: #000000;
    border: 1px solid #CDD3FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 0 10px 25px 10px;
    padding: 10px 10px 10px 10px;
`;

const DisableButton= styled(BlackButton)`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #8C8C8C;
    box-shadow: inset 3px 4px 10px rgba(0, 0, 0, 0.25);
`;

const PrimaryButton = styled(BlackButton)`
    display: inline;
    background: #6072F3;
`;

const RegisButton = styled(BlackButton)<{ customColor: string }>`
    color: ${(props) => props.customColor};
    border: 1px solid ${(props) => props.customColor};
`;

export const SearchButton = styled.input`
    position: relative;
    width: 25px;
    height: 25px;
    margin-bottom: 20px;
    padding: 13.5px;
    border: 1px solid #EFF1FF;
    border-radius: 5px;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

export {BlackButton, PrimaryButton, DisableButton, RegisButton} ;