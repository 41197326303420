import CardBG from '../components/CardBG';
import { CardName, EditText, MemID, MemInfo } from '../components/Text';
import Center from '../components/Center';
import { MyLink } from '../components/MyLink';
import { Border } from '../components/Border';
import { BlackButton} from '../components/Button';
import { QRCodeSVG } from 'qrcode.react';
import { UserAsResponse, USERTYPE_TO_INT } from '../controller/UsersAPI';
import { Link } from "react-router-dom";
import sb_logo_black from '../public/LogoBlack.svg'
import sb_logo_white from '../public/LogoWhite.svg'
import thumbup from '../public/UnicornCEOThumbsup.png';
import herenicorn from '../public/H_03.png';
import TU_logo from'../public/TU Logo.svg';
import { Faculty } from '../controller/FacultyAPI';
interface CardProps {
    user: UserAsResponse,
    profilePic: string,
    QRcontent: string,
    setIsRegis: (v:boolean) => void,
    setCondition: (v:boolean) => void,
    faculties: Faculty[],
}
function Card(props: CardProps){
    const { setCondition, user, profilePic, QRcontent, faculties } = props;
    const { user_type, firstname, lastname, position, organization, team_name, faculty_id } = user;
    return ( 
        <Center>
            <CardBG user_type = { user_type }>
                <Link to="/regis-form">
                    <EditText onClick = { () => setCondition(false) }>edit</EditText>
                </Link>
                { user_type === USERTYPE_TO_INT["StartupTeam"]?
                    <>
                        <img 
                            src = {sb_logo_white} 
                            style={{width: '80px'}} 
                            alt='88sandbox_logo'
                        />
                        <Center style = {{ margin: '0' }}>
                            <img src = { profilePic } 
                            alt = 'profile pic' 
                            style = {{ height:'82px', width: '82px', borderRadius: '50%', display: 'flex', alignContent: 'center'}}
                        />
                        </Center>
                        <CardName style={{color:'#FFFFFF'}}>
                            { firstname }<br/>
                            { lastname }
                        </CardName>
                        <Center style={{ margin: '0' }}>
                            <Border/>
                        </Center>
                        <MemInfo style={{color:'#FFFFFF'}}>
                            Startup Team<br/>
                            @{ team_name as string }
                        </MemInfo>   
                        <div style = {{ margin: '0 60px 0 60px', paddingTop: '15px', paddingBottom: '1px', backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
                            <QRCodeSVG 
                                value={QRcontent} 
                                style={{height: '110px', width: '110px', padding: '5px', backgroundColor: 'white'}}
                            />
                            <MemID style={{color:'#FFFFFF'}}>
                                #Unicorn{QRcontent}
                            </MemID>
                        </div>
                    </>
                    : null
                }
                { user_type !== USERTYPE_TO_INT["StartupTeam"]?
                    <>
                        <img 
                            src = {sb_logo_black} 
                            style={{width: '80px'}} 
                            alt='88sandbox_logo'
                        />
                        <Center style = {{ margin: '0' }}>
                            <img 
                                src = { profilePic } 
                                alt = 'profile pic' 
                                style = {{ height:'82px', width: '82px', borderRadius: '50%', display: 'flex', alignContent: 'center'}}
                            />
                            { user_type === USERTYPE_TO_INT["TUStudent"]?
                                <img 
                                    src = {TU_logo}
                                    alt = 'tu_logo' 
                                    style={{height: '25px', width: '25px', position: 'absolute', left: '52vw', top: '307px'}}
                                />
                                :null
                            }
                        </Center>
                        <CardName>
                            { firstname }<br/>
                            { lastname }
                        </CardName>
                        <Center style={{ margin: '0' }}>
                            <Border/>
                        </Center>
                        { user_type === USERTYPE_TO_INT["Mentor"] || user_type === USERTYPE_TO_INT["VC"]?
                            <MemInfo>
                                { position as string }<br/>
                                @{ organization as string }
                            </MemInfo>   
                            :null
                        }
                        { user_type === USERTYPE_TO_INT["TUStudent"]?
                            <MemInfo>
                                { user.student_id as string }<br/>
                                { faculties.map((fac) => {
                                    if(fac.faculty_id===faculty_id){
                                        return <div key={fac.faculty_id}>@{fac.name}</div>
                                    }else return null;
                                })}
                            </MemInfo>
                            :null 
                        }
                        { user_type === USERTYPE_TO_INT["Visitor"]?
                            <MemInfo>
                                Visitor<br/>
                                @88Sandbox
                            </MemInfo>
                            :null 
                        }
                        { user_type === USERTYPE_TO_INT["RoadShow"]?
                            <MemInfo>
                                Participant<br/>
                                @Roadshow
                            </MemInfo>      
                            :null 
                        }
                        <Center style={{ margin: '0' }}>
                            <div style = {{ height: '150px', width: '150px', paddingTop: '15px', paddingBottom: '1px', backgroundColor: 'rgba(255, 255, 255, 0.2)'}}>
                                <QRCodeSVG 
                                    value={QRcontent} 
                                    style={{height: '110px', width: '110px', padding: '5px', backgroundColor: 'white'}}
                                />
                                { user_type === USERTYPE_TO_INT["TUStudent"] || user_type === USERTYPE_TO_INT["RoadShow"]?
                                    <MemID>#Herenicorn{QRcontent}</MemID>
                                    :<MemID>#Unicorn{QRcontent}</MemID>
                                }
                            </div>
                        </Center>
                    </>
                    : null
                }
            </CardBG>
            <MyLink to='/'><BlackButton>Back to home</BlackButton></MyLink>
            <div>
                { user_type === USERTYPE_TO_INT["TUStudent"] || user_type === USERTYPE_TO_INT["RoadShow"]?
                    <img 
                        src={herenicorn} 
                        alt='img_herenicorn'
                        style={{height: '93px', width: '113px', position: 'relative', right: '115px', bottom: '220px'}}
                    />
                    :<img 
                        src={thumbup} 
                        alt='img_thumbsup'
                        style={{height: '125px', width: '125px', position: 'relative', right: '114px', bottom: '240px'}}
                    />
                }
            </div>
        </Center>
    );
} 
export default Card;